<template>
   <div class="welcome">
          <div class="welcome-logo">
              <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2" alt="">
          </div>
          <div class="welcome-content">
              <h1>ברוך הבא</h1>
              <h2> שם משתמש: {{user.name}}</h2>
              <h2 v-if="user.branche">סניף: {{user.branche}}</h2>
              <el-button @click="handle_next" class="next-btn" style="width:80%;" type="success">המשך</el-button>
          </div>

      </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../../../store'
import { computed } from '@vue/runtime-core'
export default {
    emits:['next'],
    setup(_,{emit}){
        const user = ref(computed(()=>{
            return store.state.user
        }))

        const handle_next = ()=>{
            emit('next')
        }
        return{user,handle_next}
    }
}
</script>

<style scoped>
    .welcome{
        width: 100%;
        max-width: 500px;
        height: 400px;
        background: var(--secondary);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .welcome-logo{
        padding: 5px 0;
        width: 100%;
        height: 150px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .welcome-logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .welcome-content{
        position: relative;
        width: 100%;
        height: calc(100% - 150px);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
    }
    .next-btn{
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
</style>