<template>
  <div v-if="platform=='desktop' || platform=='tablet'" class="manage-forms">
      <div class="manage-form-tools">
          <el-input v-model="search" style="width:300px;"  placeholder="חיפוש לפי תאריך/סניף/ברקוד/שם פריט" />
          <input v-model="fromDate" type="date">
          <input v-model="toDate" type="date">
          <el-select style="width:150px;" v-model="selected_branche" filterable clearable placeholder="סנן לפי סניף">
                <el-option
                    v-for="branche in branches_list"
                    :key="branche"
                    :value="branche"
                >
            </el-option>
            
          </el-select>
          <el-button @click="handle_export_to_scv" type="success" round>ייצוא לאקסל</el-button>
      </div>
      <div class="manage-form-dashboard">
          <div class="dashboard-display">
              <h4>סך רשומות</h4>
              {{sorted_records.length}}
          </div>
      </div>
      <div class="manage-form-options">
            <el-button @click="handle_get_records_by_status('חדש')" type="success">חדשים</el-button>
            <el-button @click="handle_get_records_by_status('טופל')" style="margin-right:5px;" type="danger">טופלו</el-button>
      </div>
      <div class="manage-form-content">
          <table>
              <tr>
                  <th style="cursor:pointer" @click="handleSortTable('תאריך', !selectedSorted.order)">נוצר בתאריך</th>
                  <th style="cursor:pointer" @click="handleSortTable('סניף', !selectedSorted.order)">סניף</th>
                  <th style="cursor:pointer" @click="handleSortTable('ברקוד', !selectedSorted.order)">ברקוד</th>
                  <th style="cursor:pointer" @click="handleSortTable('מוצר', !selectedSorted.order)">שם המוצר</th>
                  <th>כמות</th>
                  <th>בונוס</th>
                  <th>סטטוס</th>
                  <th>פעולות</th>
              </tr>
              <h1 v-if="sorted_records.length==0">אין רשומות</h1>
              <template v-for="record in sorted_records" :key="record.uid">
                  <tr>
                      <td>{{new Date(record.creates_at.seconds * 1000).toLocaleDateString('he')}}</td>
                      <td>{{record.branche}}</td>
                      <td>{{record.barcode}}</td>
                      <td>{{record.product_name}}</td>
                      <td v-if="record.qnt_type">{{record.qnt}} {{record.qnt_type}}</td>
                      <td v-else>{{record.qnt}}</td>
                      <td v-if="record.bonus_type">{{record.bonus_qnt}} {{record.bonus_type}}</td>
                      <td v-else>{{record.bonus_qnt}}</td>
                      <td v-if="record.status=='חדש'" style="color:green">{{record.status}}</td>
                      <td v-if="record.status=='טופל'" style="color:red">{{record.status}}</td>
                      <td>
                        <i @click="handle_setting(record)" class="material-icons settings-icon">
                            settings
                        </i>
                        <i @click="handle_edit(record)" class="material-icons edit-icon">
                            edit
                        </i>
                        <i @click="handle_delete(record)" class="material-icons delete-icon">
                            delete
                        </i>
                      </td>
                  </tr>
              </template> 
          </table>
      </div>

      <Settings @update="handle_update_record" @close="show_setting_record=false" :record="selected_record" v-if="show_setting_record" />
      <Loader v-if="is_pending" />
      <div  v-if="show_edit_form" class="edit-record slide-in-top">
        <BonusForm @update_record="handle_update_after_edit" :edit_record="selected_record" />
        <div @click="show_edit_form=false" class="edit-exit">
            X
        </div>
      </div>
  </div>
  <OnlyForDesktop v-if="platform=='mobile'" />
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import {projectFirestore} from '../../../../firebase/config'
import Settings from './Management/Settings.vue'
import Loader from '../../officeRequests/components/manage_side/loader/Loader.vue'
import BonusForm from './Form/BonusForm.vue'
import { computed } from '@vue/runtime-core'
import {alert_confirm} from '../../../../Methods/Msgs'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import XLSX from "xlsx";
import store from '../../../../store'
export default {
    components:{Settings,BonusForm,Loader,OnlyForDesktop},
    setup(){
        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        const is_pending = ref(false)
        const selected_record  = ref(null)
        const records = ref([])
        const branches_list = ref([])
        const selected_branche = ref('')
        const search = ref('') 
        const fromDate = ref('')
        const toDate = ref('')
        const show_setting_record = ref(false)
        const show_edit_form = ref(false)

        const handle_export_to_scv = ()=>{
            let rows = JSON.parse(JSON.stringify(sorted_records.value))

            let workbook = XLSX.utils.book_new();

            let workSheetData =[]
            workSheetData.push([
                       'נוצר בתאריך',
                       'סניף',
                       'ברקוד',
                       'שם המוצר',
                       'כמות',
                       'כמות בונוס',
                       'סטטוס'
            ])
            
        
            rows.forEach((row)=>{
                workSheetData.push([
                    new Date(row.creates_at.seconds * 1000).toLocaleDateString('he').split('.').join('/'),
                    row.branche,
                    row.barcode,
                    row.product_name,
                    row.qnt,
                    row.bonus_qnt,
                    row.status 
                ])
                
            })

            

            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "עשר פלוס אחד");
            XLSX.writeFile(workbook, "עשר פלוס אחד.xlsx");
        }
        const handle_edit = (record)=>{
            selected_record.value = record
            show_edit_form.value = true
        }
        const handle_delete = (record)=>{
            alert_confirm(`למחוק את ${record.product_name}?`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await projectFirestore.collection('Applications').doc('2l5VnaCyL99x0EhM7Gw6')
                    .collection('forms').doc(record.uid).delete()
                    const index = records.value.findIndex(_record=>_record.uid == record.uid)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                }
            })
        }
        const handle_update_after_edit = async(status)=>{
            await handle_get_records_by_status(status)
            show_edit_form.value = false
        }
        const handle_update_record = (uid)=>{
            show_setting_record.value = false
            console.log(uid);
            const index = records.value.findIndex(record=>record.uid == uid)
            if(index!=-1){
                records.value.splice(index,1)
            }
        }
        const handle_setting = (record)=>{
            selected_record.value = record
            show_setting_record.value = true
        }
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sorted_records = ref(computed(()=>{
            if (selectedSorted.value.name == "") return filter_by_branche.value
            if (selectedSorted.value.name == "תאריך") {
                return filter_by_branche.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.creates_at.seconds * 1000) > new Date(b.creates_at.seconds * 1000)
                        ? 1
                        : new Date(b.creates_at.seconds * 1000)  > new Date(a.creates_at.seconds * 1000) 
                        ? -1
                        : 0;
                    }
                    return new Date(a.creates_at.seconds * 1000)  > new Date(b.creates_at.seconds * 1000) 
                    ? -1
                    : new Date(b.creates_at.seconds * 1000)  > new Date(a.creates_at.seconds * 1000) 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סניף") {
                return filter_by_branche.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.branche > b.branche
                        ? 1
                        : b.branche  > a.branche 
                        ? -1
                        : 0;
                    }
                    return a.branche  > b.branche
                    ? -1
                    : b.branche  > a.branche
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "ברקוד") {
                return filter_by_branche.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.barcode > b.barcode
                        ? 1
                        : b.barcode  > a.barcode 
                        ? -1
                        : 0;
                    }
                    return a.barcode  > b.barcode
                    ? -1
                    : b.barcode  > a.barcode
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מוצר") {
                return filter_by_branche.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.product_name > b.product_name
                        ? 1
                        : b.product_name  > a.product_name 
                        ? -1
                        : 0;
                    }
                    return a.product_name  > b.product_name
                    ? -1
                    : b.product_name  > a.product_name
                    ? 1
                    : 0;
                });
            }
        }))

        const filter_by_branche = ref(computed(()=>{
            if(selected_branche.value == '') return filter_by_search.value
            return filter_by_search.value.filter((record)=>{
                if(record.branche == selected_branche.value) return record
            })
        }))
        const filter_by_search = ref(computed(()=>{
            if (search.value == ""){
                if(fromDate.value && toDate.value){
                    return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.creates_at) &&
                        stringTodate(toDate.value)>=timestampToDate(record.creates_at) ){
                            return record
                        }                    
                    })
                
                }
                return records.value;
            }
            else{
                let results = records.value.filter(record => {
                    if(new Date(record.creates_at.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record
                    if(record.branche.includes(search.value)) return record
                    if(record.barcode.includes(search.value)) return record
                    if(record.product_name.includes(search.value)) return record
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.creates_at) &&
                            stringTodate(toDate.value)>=timestampToDate(record.creates_at) ){
                                return record
                        }
                    })
                }
                return results
            }
        }))
        
        const init = async()=>{
            branches_list.value = await get_branches_from_db()
            await handle_get_records_by_status('חדש')
        }

        const handle_get_records_by_status = async(status)=>{
            is_pending.value = true
            const docs = await projectFirestore.collection('Applications').doc('2l5VnaCyL99x0EhM7Gw6')
            .collection('forms').where('status','==',status).orderBy('creates_at','desc').get()
            records.value = docs.docs.map(doc=>doc.data())
            is_pending.value = false
        }

        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        init()
        return{
            handle_update_record,
            handle_get_records_by_status,
            selected_branche,
            branches_list,
            records,
            fromDate,
            toDate,
            filter_by_search,
            filter_by_branche,
            search,
            handleSortTable,
            handle_setting,
            handle_update_after_edit,
            handle_export_to_scv,
            selectedSorted,
            sorted_records,
            selected_record,
            show_setting_record,
            show_edit_form,
            handle_delete,
            handle_edit,
            is_pending,
            platform
        }
    }
}
</script>

<style scoped>
    .manage-forms{
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .manage-form-tools{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .manage-form-dashboard{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .dashboard-display{
        width: 200px;
        height: 100%;
        border-radius: 5px;
        background: var(--purple);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:#fff;
    }
    .manage-form-options{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .manage-form-content{
        width: 100%;
        height: calc(100% - 180px);
        overflow:hidden;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;

    }
    table th {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
        background-color: var(--secondary);
        color: white;
        position: sticky;
        top: 0;
    }
    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .settings-icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
        cursor: pointer;
    }
    .edit-icon{
        color: var(--warning);
        cursor: pointer;
    }
    .edit-record{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    .edit-exit{
        position: absolute;
        top: 0;
        left: 5%;
        z-index: 6;
        width: 60px;
        height: 60px;
        font-size: 50px;
        font-weight: 500;
        background: var(--danger);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
    }
     .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
</style>