<template>
  <div class="ten-plus-one-main">
      <div v-if="!state" class="main-btns">
          <button @click="state='NewForm'" class="btn btn-succes">
               טופס חדש
          </button>
          <button v-if="role>=99" @click="state='ManageForms'" class="btn btn-warning">
                ניהול
          </button>
      </div>
      <div v-if="state!=null" class="main-state">
          <component :is="state" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewForm from './components/NewForm.vue'
import ManageForms from './components/ManageForms.vue'
import { computed } from '@vue/runtime-core'
import store from '../../../store'
import {handle_back_button_on_browser} from '../../../Methods/Back_button_browser'
export default {
    components:{NewForm,ManageForms},
    setup(){
        const {state}  = handle_back_button_on_browser()
        const role = ref(computed(()=>{
            return store.state.role
        }))
       
        return{state,role}
    }
}
</script>

<style scoped>
    .ten-plus-one-main{
        width: 100%;
        height: 100%;
    }
    .btn{
        width: 300px;
        height: 100px;
        border: 0;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 25px;
        cursor: pointer;
    }
    .btn-succes{
        background: var(--success);
        color: #fff;
    }
    .btn-warning{
        background: var(--yellow);
    }
    .main-btns{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        display:  flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-state{
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }
</style>